.searchBox {
  position: absolute;
  z-index: 11;
  top: 1rem;
  left: 0;
  background: $bg-dark;
  height: 80px;
  border-radius: 0 45px 45px 0;
  display: flex;
  justify-self: start;
  @include short-view{
    height: 60px;
    
  }
  &:hover {
    .searchInput {
      width: 50vw;
      padding: 0 0 0 30px;
    }
    .searchButton {
      background: $white;
      color: $search-bg;
    }
  }

}

.searchBox:hover > .searchInput {
  width: 50vw;
  padding: 0 0 0 30px;
}
.search-error {
  animation: shake 300ms;
}
.searchBox:hover > .searchButton {
  background: $white;
  color: $search-bg;
}

.searchButton {
  color: $white;
  float: left;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: $search-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  box-shadow: 3px 5px 30px $background;
  @include short-view {
    width: 60px;
    height: 60px;
  }
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: right;
  padding: 0;
  color: $white;
  font-size: 32px;
  transition: 0.2s;
  line-height: 40px;
  width: 0px;
  box-shadow: 3px 5px 30px $background;
  @include short-view {
    line-height: 16px;
    transition: 0.3s;
    line-height: 20px;

  }
}
.expand {
  width: 50vw;
  padding: 0 0 0 30px;
}
.search-svg {
  position: absolute;
  z-index: 11;
  transform: scale(0.5);
  @include short-view {
    transition: 0.2s;

    transform: scale(0.33);

  }

}

@media screen and (max-width: 620px) {
  .searchBox {
    &:hover {
      .searchInput {
        width: 50vw;
        padding: 0 6px;
      }
    }
    .expand {
      .searchInput {
        width: 50vw;
        padding: 0 6px;
      }
    }
  }
}
.project-search {
  display: flex;
  flex-direction: column;
}
.project-404 {
  margin-top: 7rem;
  text-align: center;
  font-size: calc(5px + 3rem);
}

.App {
  text-align: center;
}

.App-header {
  display: flex;
  font-family: $nav-sans;
  font-size: calc(23px + 2vmin);
  font-weight: bold;
  color: $white;
}

.App-link {
  color: $link;
}
.App-logo {
  height: 15vmax;
  max-height: 8rem;
  pointer-events: none;
  margin-right: 20px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.intro {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  text-align: left;
  z-index: 0;
  padding: 1rem;
  text-shadow: 4px 4px 3px $black;
  display: flex;
  flex-direction: row;
  h1 {
    font-size: calc(10px + 5vmin);
    padding-bottom: 0.5rem;
  }
  p {
    font-size: calc(10px + 3vmin);
  }
}

.content-wrap {
    .close-panel {
      background-color: $bg-dark;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding-left: 0.5rem;
      width: 11vw;
      max-width: 5.5rem;
      border-radius: 0 15px 15px 0;
      color: $contrast;
      font-size: 3rem;
      font-weight: 900;
      font-family: $sans;
      display: flex;
      align-items: center;
      cursor: pointer;
      .close-panel-button {
        margin: 0 auto;
        user-select: none;
      }
    }
    position: absolute;
    background-color: $background;
    top: 5vh;
    bottom: 6vh;
    right: 5vw;
    left: 5vw;
    z-index: 9;
    color: $white;
    font-family: $sans;
    padding-left: 1rem;
    border-radius: 25px;
    border: solid $bg-dark 10px;
    @include short-view {
      top: 1vh;
      left: 1vw;
      bottom: 6vh;
    }
    a {
      color: $link;
      text-decoration: none;
      &:hover {
        color: $contrast;
      }
    }
    &.on {
      transform: translate(0, 0);
      transition: transform 0.3s ease-out;
    }
    &.off {
      transform: translate(calc(-200vw - 25px), 0);
      transition: transform 0.3s ease-in;
      z-index: 8;
    }
  
    @include nav-break {
      right: 7rem;
    }
    h2 {
      font-size: calc(20px + 1vw);
    }
    p {
      font-size: calc(15px + 1vw);
    }
    .content-scroll {
        margin-right: 15vw;
        padding-right: 2vw;
        height: calc(89vh - 1.2rem);
        overflow-y: auto;
        overflow-x: hidden;
        overscroll-behavior: none;
      
        line-height: 1.25;
        @include short-view {
          height: 100%;
        }
        @include nav-break {
          margin-right: 8rem;
        }
        /* width */
        &::-webkit-scrollbar {
          width: 10px;
        }
      
        /* Track */
        &::-webkit-scrollbar-track {
          background: $background;
        }
      
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $contrast;
          border-radius: 10px;
        }
      
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $link;
          border-radius: 10px;
        }
      }
      
  }
  
.splash-wrap {
  margin: 2rem;
  position: relative;
  width: calc(100vw - 16rem);
  min-width: 70vw;
  min-height: 30vh;
  //max-height: calc(100vh - 24rem);
  max-width: 700px;
  z-index: 1;
  top: calc(5vh + 5rem);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  border-radius: 15px;
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 10px;
    height: 0;
    overflow: hidden;
  }
  .ThreeScene {
    width: 80%;
    height: 80%; /* Adjust the height as needed */
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 315px;
    max-width: 560px;
  }
  .splash-bg {
    width: calc(100vw - 16rem);
    min-width: 70vw;
    max-width: 560px;
    background-color: $background;
    border-radius: 25px;

    border: solid 10px $bg-dark;

    .splash-header {
      color: $white;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      font-size: calc(20px + 2vmin);
      font-family: $sans;
      font-weight: bold;
      padding-right: 3vw;

      background-color: $bg-dark;
      text-align: right;
      user-select: none;
      @include nav-break {
        padding-right: 5rem;
      }
    }
    .splash-border {
      min-height: 10vh;
      width: calc(100vw - 21.5rem);
      min-width: 70vw;
      margin: auto;

      .splash-body {
        color: $white;
        display: grid;
        font-family: $sans;
        font-weight: bold;
        background-color: $background;
        font-size: calc(1rem + 4vw);
        max-height: calc(100vh - 23rem);
        border-radius: 10px;
        @include short-view {
          max-height: calc(100vh - 25rem);
        }
        align-self: start;

        width: calc(100vw - 21.5rem);
        min-width: 70vw;

        column-gap: normal;
        grid-template-areas:
          "title"
          "img"
          "description"
          "bullet";

        @include phone-view {
          grid-template-areas:
            "title title"
            "img  bullet"
            "description description";
        }


        /* width */
        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: $background;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $contrast;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $link;
          border-radius: 10px;
        }
        @include phone-view {
        }
        .splash-title {
          display: flex;
          justify-content: space-between;
          grid-area: title;
          font-family: $sans;
          margin: 0.5rem;
          svg {
            width: calc(14px + 4vmin);
            padding-right: 1vw;
            fill: $contrast;
            &:hover {
              fill: $link;
            }
          }
        }
        .splash-img {
          grid-area: img;
          text-align: center;

          img {
            margin: 1rem;
            min-width: 10rem;
            width: calc(25px + 50vw);
            border-radius: 10px;

            @include phone-view {
              left: 0;
              max-width: calc(75px + 15vw);
            }
          }
        }
        .splash-description {
          grid-area: description;
          text-align: justify;
          margin: 1rem;

          font-size: calc(10px + 3vmin);
        }
        .splash-bullet {
          grid-area: bullet;
          font-size: calc(13px + 2vmin);
          text-align: left;
          width: 50vw;
          margin: 1rem 1em 1rem 2rem;
          @include phone-view {
            width: 30vw;
          }
          ul {
            list-style: circle outside;

            li {
              margin-left: 0rem;
            }
          }
        }
      }
    }
  }
}
